import React from 'react';
import './App.css';
import Sticky from 'react-sticky-el';
import { Contact } from "./components/Contact";
import YouTube from "react-youtube";

function App() {
    return (
        <div className="w-full">
            <div className="videoWrapper">
                <YouTube
                    videoId="C-SHvtJthRY"
                    opts={{
                        height: '390',
                        width: '640',
                        playerVars: {
                            // https://developers.google.com/youtube/player_parameters
                            autoplay: 1,
                            controls: 1,
                            modestbranding: 1,
                            loop: 1,
                            mute: 1,
                            rel: 0,
                        },
                    }}
                />
            </div>

            {/*Intro*/}
            <div className="overflow-hidden">
                <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="hidden lg:block bg-gray-900 absolute top-0 bottom-0 left-3/4 w-screen"/>
                    <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                        <div>
                            <h2 className="text-base text-yellow-500 font-semibold tracking-wide uppercase">BEMUTATKOZÁS</h2>
                            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-50 sm:text-4xl">
                                Szokor Gyula
                            </h3>
                        </div>
                    </div>
                    <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                        <div className="relative lg:row-start-1 lg:col-start-2">
                            <svg
                                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                                width={404}
                                height={384}
                                fill="none"
                                viewBox="0 0 404 384"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect
                                            x={0}
                                            y={0}
                                            width={4}
                                            height={4}
                                            className="text-gray-800"
                                            fill="currentColor"
                                        />
                                    </pattern>
                                </defs>
                                <rect
                                    width={404}
                                    height={384}
                                    fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                                />
                            </svg>
                            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                                <figure>
                                    <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                                        <img
                                            alt='portrait'
                                            className="shadow-lg object-cover object-center"
                                            src={process.env.PUBLIC_URL + '/portrait.jpeg'}
                                            width={1184}
                                            height={1376}
                                        />
                                    </div>
                                </figure>
                            </div>
                        </div>
                        <div className="mt-8 lg:mt-0">
                            <div className="text-lg text-gray-400 text-base max-w-prose mx-auto lg:max-w-none">
                                <p>
                                    A korai televíziós készülékek, elég kezdetlegesek voltak.
                                    A fekete-fehér felvételen is fantasztikus volt Hacki Tamás, füttyművész produkciója.
                                </p>
                                <br/>
                                <p>
                                    Teljesen lenyűgözött, s ahogy az a gyermekeknél lenni szokott, én is az utánozásába
                                    kezdtem.
                                    10 évesen már egy komplett zeneszámot sikerült megtanulnom és előadnom egy mindenki
                                    számára nagyon érdekes fütyülési technikával.
                                    Édesanyám büszkén dicsekedett fia tudásával. Számos helyen adtam elő a Pattogatott
                                    kukorica című számot.
                                </p>
                                <br/>
                                <p>
                                    Egy alkalommal a rokonság egyik tagja, a látottak után büszkélkedett, hogy ő bizony
                                    tud néhány bűvésztrükköt!
                                    A többit már gondolhatják!
                                </p>
                                <br/>
                                <p>
                                    Annak hatására bűvész szakkörök, bűvész klubok tagjává, bűvészversenyek
                                    résztvevőjévé váltam. Kisebb-nagyobb szünetekkel, de a mai napig kedvenc hobbim
                                    maradt a bűvészkedés. Kicsiket és nagyokat egyaránt szeretek elkápráztatni, s
                                    remélem mindenki örömére, hogy nem füttyművészként!
                                </p>
                            </div>
                            <div className="text-lg mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Mozgo hatter*/}
            <Sticky>
                <video
                    className='hidden absolute lg:block min-h-full w-screen'
                    autoPlay={true}
                    loop={true}
                    controls={false}
                    muted={true}
                >
                    <source
                        src={process.env.PUBLIC_URL + '/webhatter.mp4'}
                        type="video/mp4"
                    />
                </video>
            </Sticky>

            <div className='lg:px-48'>
                {/*Quote*/}
                <section className="overflow-hidden pt-16">
                    <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
                        <svg
                            className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
                            width={784}
                            height={404}
                            fill="none"
                            viewBox="0 0 784 404"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect
                                        x={0}
                                        y={0}
                                        width={4}
                                        height={4}
                                        className="text-gray-900"
                                        fill="currentColor"
                                    />
                                </pattern>
                            </defs>
                            <rect
                                width={784}
                                height={404}
                                fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
                            />
                        </svg>

                        <div className="relative lg:flex lg:items-center">
                            <div className="relative lg:ml-10">
                                <svg
                                    className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-yellow-300 opacity-50"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 144 144"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeWidth={2}
                                        d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                                    />
                                </svg>
                                <blockquote className="relative">
                                    <div className="text-2xl leading-9 font-medium text-gray-100">
                                        <p>
                                            „ Művészetet nem tudja élvezni az, ki csak azt látja, amit a művész
                                            kifejezett, ám
                                            nem érzi, hogy mit hallgatott el. „
                                        </p>
                                    </div>
                                    {/*<footer className="mt-4">*/}
                                    {/*    <div className="flex">*/}
                                    {/*        <div className="ml-4 lg:ml-0">*/}
                                    {/*            <div className="text-base font-medium text-gray-300">Szokor Gyula</div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</footer>*/}
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </section>

                {/*Onallo est*/}
                <div className="relative py-16">
                    <div
                        className="hidden absolute top-0 inset-x-0 h-1/2 lg:block"
                        aria-hidden="true"
                    />
                    <div className="max-w-7xl mx-auto bg-black lg:bg-transparent lg:px-8">
                        <div className="lg:grid lg:grid-cols-12">
                            <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-6 lg:py-16 lg:bg-transparent">
                                {/*<div*/}
                                {/*    className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"*/}
                                {/*    aria-hidden="true"*/}
                                {/*/>*/}
                                <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                                    <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                                        <div className="videoWrapper">
                                            <YouTube
                                                videoId="kf3mTPV5y48"
                                                opts={{
                                                    height: '390',
                                                    width: '640',
                                                    playerVars: {
                                                        // https://developers.google.com/youtube/player_parameters
                                                        autoplay: 0,
                                                        controls: 1,
                                                        modestbranding: 1,
                                                        loop: 1,
                                                        rel: 0,
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="relative bg-gradient-to-r from-yellow-light to-yellow-dark lg:col-start-5 lg:row-start-1 lg:col-span-8 lg:grid lg:grid-cols-10 lg:items-center">
                                <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                                    <h2
                                        className="text-3xl font-extrabold text-white"
                                        id="join-heading"
                                    >
                                        ÖNÁLLÓ EST
                                    </h2>
                                    <p className="text-md text-gray-100">
                                        Mindig is lenyűgöztek a múlt csavaros történetei és megoldásai, ami maximum
                                        utólag
                                        tűnnek
                                        régimódinak.

                                        Az önálló estem első része egy játékos megközelítés 60 percben, melyben a
                                        spiritiszta szeánszok
                                        hangulata elevenedik meg. Így a nézők a valóságban is átélhetik a
                                        szellemidézést,
                                        rejtélyes
                                        véletleneket, egybeeséseket, repkedő tárgyak látványát, misztikumát.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Klasszikus buveszmusor (R) */}
                <div className="relative py-16">
                    <div
                        className="hidden absolute top-0 inset-x-0 h-1/2 lg:block"
                        aria-hidden="true"
                    />
                    <div className="max-w-7xl mx-auto bg-black lg:bg-transparent lg:px-8">
                        <div className="flex flex-col lg:grid lg:grid-cols-12">
                            <div className="order-2	relative bg-gradient-to-r from-yellow-dark to-yellow-light lg:col-start-1 lg:row-start-1 lg:col-span-8 lg:grid lg:grid-cols-10 lg:items-center">
                                <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:pl-8 lg:col-span-6">
                                    <h2
                                        className="text-3xl font-extrabold text-white"
                                        id="join-heading"
                                    >
                                        KLASSZIKUS BŰVÉSZMŰSOR
                                    </h2>
                                    <p className="text-md text-gray-100">
                                        Sokszor felmerül a klasszikus bűvésztrükkök feldolgozásának örök problémája: -
                                        ha eltérsz az
                                        eredetitől, akkor bűnt követsz el, ha hűen követed azt, akkor meg megszólnak,
                                        hogy minek az egész?
                                        <br/>
                                        Véleményem szerint, mindig lesznek olyan nézők, kik kedvelik a leleplezés és
                                        szenzációmentes
                                        előadásokat.
                                    </p>
                                </div>
                            </div>

                            <div className="order-1	relative z-10 lg:row-start-1 lg:col-span-6 lg:col-start-7 lg:py-16 lg:bg-transparent">
                                {/*<div*/}
                                {/*    className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"*/}
                                {/*    aria-hidden="true"*/}
                                {/*/>*/}
                                <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                                    <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                                        <div className="videoWrapper">
                                            <YouTube
                                                videoId="_qBk-GmywhI"
                                                opts={{
                                                    height: '390',
                                                    width: '640',
                                                    playerVars: {
                                                        // https://developers.google.com/youtube/player_parameters
                                                        autoplay: 0,
                                                        controls: 1,
                                                        modestbranding: 1,
                                                        loop: 1,
                                                        rel: 0,
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*BÁR MAGIC*/}
                <div className="relative py-16">
                    <div
                        className="hidden absolute top-0 inset-x-0 h-1/2 lg:block"
                        aria-hidden="true"
                    />
                    <div className="max-w-7xl mx-auto bg-black lg:bg-transparent lg:px-8">
                        <div className="lg:grid lg:grid-cols-12">
                            <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-6 lg:py-16 lg:bg-transparent">
                                {/*<div*/}
                                {/*    className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"*/}
                                {/*    aria-hidden="true"*/}
                                {/*/>*/}
                                <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                                    <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                                        <div className="videoWrapper">
                                            <YouTube
                                                videoId="8fRRuUULr3k"
                                                opts={{
                                                    height: '390',
                                                    width: '640',
                                                    playerVars: {
                                                        // https://developers.google.com/youtube/player_parameters
                                                        autoplay: 0,
                                                        controls: 1,
                                                        modestbranding: 1,
                                                        loop: 1,
                                                        rel: 0,
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="relative bg-gradient-to-r from-yellow-light to-yellow-dark lg:col-start-5 lg:row-start-1 lg:col-span-8 lg:grid lg:grid-cols-10 lg:items-center">
                                <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                                    <h2
                                        className="text-3xl font-extrabold text-white"
                                        id="join-heading"
                                    >
                                        BÁR MAGIC
                                    </h2>
                                    <p className="text-md text-gray-100">
                                        Színpad, vagy annak hiányában is bemutatható zenés bűvészműsorok. Olyan
                                        rendezvényekre ajánlom,
                                        melyben a közönség bevonása nélkül ( érintésmentes) szórakoztatom nézőimet.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*CLOSE-UP (R) */}
                <div className="relative py-16">
                    <div
                        className="hidden absolute top-0 inset-x-0 h-1/2 lg:block"
                        aria-hidden="true"
                    />
                    <div className="max-w-7xl mx-auto bg-black lg:bg-transparent lg:px-8">
                        <div className="flex flex-col lg:grid lg:grid-cols-12">
                            <div className="order-2 relative bg-gradient-to-r from-yellow-dark to-yellow-light lg:col-start-1 lg:row-start-1 lg:col-span-8 lg:grid lg:grid-cols-10 lg:items-center">
                                <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:pl-12 lg:col-span-6">
                                    <h2
                                        className="text-3xl font-extrabold text-white"
                                        id="join-heading"
                                    >
                                        CLOSE-UP
                                    </h2>
                                    <p className="text-md text-gray-100">
                                        Kisebb létszámú közönségnek, közelről bemutatható bűvésztrükkök.
                                    </p>
                                </div>
                            </div>

                            <div className="order-1 relative z-10 lg:row-start-1 lg:col-span-6 lg:col-start-7 lg:py-16 lg:bg-transparent">
                                {/*<div*/}
                                {/*    className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"*/}
                                {/*    aria-hidden="true"*/}
                                {/*/>*/}
                                <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                                    <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                                        <div className="videoWrapper">
                                            <YouTube
                                                videoId="m5IgMmVO3cg"
                                                opts={{
                                                    height: '390',
                                                    width: '640',
                                                    playerVars: {
                                                        // https://developers.google.com/youtube/player_parameters
                                                        autoplay: 0,
                                                        controls: 1,
                                                        modestbranding: 1,
                                                        loop: 1,
                                                        rel: 0,
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*GYEREKMŰSOROK */}
                <div className="relative py-16">
                    <div
                        className="hidden absolute top-0 inset-x-0 h-1/2 lg:block"
                        aria-hidden="true"
                    />
                    <div className="max-w-7xl mx-auto bg-black lg:bg-transparent lg:px-8">
                        <div className="lg:grid lg:grid-cols-12">
                            <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-6 lg:py-16 lg:bg-transparent">
                                {/*<div*/}
                                {/*    className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"*/}
                                {/*    aria-hidden="true"*/}
                                {/*/>*/}
                                <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                                    <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                                        <div className="videoWrapper">
                                            <YouTube
                                                videoId="76QF7qI79kc"
                                                opts={{
                                                    height: '390',
                                                    width: '640',
                                                    playerVars: {
                                                        // https://developers.google.com/youtube/player_parameters
                                                        autoplay: 0,
                                                        controls: 1,
                                                        modestbranding: 1,
                                                        loop: 1,
                                                        rel: 0,
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="relative bg-gradient-to-r from-yellow-light to-yellow-dark lg:col-start-5 lg:row-start-1 lg:col-span-8 lg:grid lg:grid-cols-10 lg:items-center">
                                <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                                    <h2
                                        className="text-3xl font-extrabold text-white"
                                        id="join-heading"
                                    >
                                        GYEREKMŰSOROK
                                    </h2>
                                    <p className="text-md text-gray-100">
                                        A ’ pillanat ’, mikor a legkisebbek is bűvészinasnak állhatnak.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* VIDEOK */}

                <div className='relative py-16'>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                        <div className="videoWrapper">
                            <YouTube
                                videoId="Tm9JOW-Zfwg"
                                opts={{
                                    height: '390',
                                    width: '640',
                                    playerVars: {
                                        // https://developers.google.com/youtube/player_parameters
                                        autoplay: 0,
                                        controls: 1,
                                        modestbranding: 1,
                                        loop: 1,
                                        rel: 0,
                                    },
                                }}
                            />
                        </div>

                        <div className="videoWrapper">
                            <YouTube
                                videoId="HuOhUQg0NPY"
                                opts={{
                                    height: '390',
                                    width: '640',
                                    playerVars: {
                                        // https://developers.google.com/youtube/player_parameters
                                        autoplay: 0,
                                        controls: 1,
                                        modestbranding: 1,
                                        loop: 1,
                                        rel: 0,
                                    },
                                }}
                            />
                        </div>

                        <div className="videoWrapper">
                            <YouTube
                                videoId="JMm1OPNdEyI"
                                opts={{
                                    height: '390',
                                    width: '640',
                                    playerVars: {
                                        // https://developers.google.com/youtube/player_parameters
                                        autoplay: 0,
                                        controls: 1,
                                        modestbranding: 1,
                                        loop: 1,
                                        rel: 0,
                                    },
                                }}
                            />
                        </div>

                        <div className="videoWrapper">
                            <YouTube
                                videoId="Apx0-nlofQA"
                                opts={{
                                    height: '390',
                                    width: '640',
                                    playerVars: {
                                        // https://developers.google.com/youtube/player_parameters
                                        autoplay: 0,
                                        controls: 1,
                                        modestbranding: 1,
                                        loop: 1,
                                        rel: 0,
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className='relative py-16'>
                    <Contact/>
                </div>
            </div>
        </div>
    );
}

export default App;
