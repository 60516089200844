import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const Contact: React.FC = () => {
    return (
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg mx-auto md:max-w-none">
                <div>
                    <div className='text-center'>
                        <h2 className="text-2xl font-extrabold text-white sm:text-3xl">ELÉRHETŐSÉGEK</h2>
                    </div>
                    <div className="mt-9 text-center">
                        <a
                            className='mb-2 block'
                            href='tel:06304985007'
                        >
                            <FontAwesomeIcon
                                className="h-6 w-6 text-gray-200 relative top-1"
                                icon={faPhone}
                            />
                            <span className="ml-3 text-base text-gray-300">
                                06204985007
                            </span>
                        </a>
                        <a
                            className='mb-2 block'
                            href='mailto:szokorgy@gmail.com'
                        >
                            <FontAwesomeIcon
                                className="h-6 w-6 text-gray-200 relative top-1"
                                icon={faEnvelope}
                            />
                            <span className="ml-3 text-base text-gray-300">
                                szokorgy@gmail.com
                            </span>
                        </a>
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href='https://www.instagram.com/gyulaszokor/'
                        >
                            <FontAwesomeIcon
                                className="h-6 w-6 text-gray-200 relative top-1"
                                icon={faInstagram}
                            />
                            <span className="ml-3 text-base text-gray-300">gyulaszokor</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Contact };
